import { createAsync } from '@solidjs/router';
import { ArticleTile, Container, Heading, HorizontalRule, Page, Pagination, Section } from '@troon/ui';
import { Meta, Title } from '@solidjs/meta';
import { For, Show } from 'solid-js';
import dayjs from '@troon/dayjs';
import { createContentfulListRequest } from '../content/[model]/_client';
import { RichText } from '../../components/rich-text';
import { UpsellAccess } from '../../components/upsells/access';

const perPage = 9;

export default function TheTroonInsider() {
	const articles = createAsync(async () => getArticles(), { deferStream: true });

	return (
		<Container>
			<Title>The Troon Insider</Title>
			<Meta
				name="description"
				content="The Troon Insider is your all-access pass to the world of golf. From must-visit destinations and industry trends to player insights and the latest from Troon…"
			/>
			<Page class="md:gap-16">
				<Section>
					<Heading as="h1">The Troon Insider</Heading>
					<p class="max-w-3xl">
						The Troon Insider is your all-access pass to the world of golf. From must-visit destinations and industry
						trends to player insights and the latest from Troon, we bring you the stories that make the game great.
						Follow along as we uncover hidden gems, share exclusive behind-the-scenes moments, and experience the world
						through golf.
					</p>
				</Section>

				<HorizontalRule />

				<Section>
					<Heading as="h2" size="h4">
						Recent Posts
					</Heading>
					<div class="grid grid-cols-12 gap-8">
						<For each={articles()?.items.slice(0, 3)}>
							{(article, i) => (
								<div class={i() === 0 ? 'col-span-12 row-span-2 md:col-span-8' : 'col-span-6 md:col-span-4'}>
									<ArticleTile
										title={article.fields.title}
										url={`/insider/${article.fields.slug}`}
										date={dayjs(article.fields.publishDate, 'America/Phoenix').toDate()}
										hero={
											article.fields.heroImage
												? {
														src: article.fields.heroImage?.fields.file?.url,
														alt:
															article.fields.heroImage?.fields.description ??
															article.fields.heroImage?.fields.title ??
															'',
														preload: true,
														loading: 'eager',
													}
												: undefined
										}
									>
										<RichText document={article.fields.summary} />
									</ArticleTile>
								</div>
							)}
						</For>
					</div>
				</Section>

				<UpsellAccess location="troon-insider" />

				<Show when={(articles()?.items.length ?? 0) > 3}>
					<Section>
						<Heading as="h2" size="h4">
							All Blog Posts
						</Heading>

						<div class="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
							<For each={articles()?.items.slice(3)}>
								{(article) => (
									<ArticleTile
										title={article.fields.title}
										url={`/insider/${article.fields.slug}`}
										date={dayjs(article.fields.publishDate, 'America/Phoenix').toDate()}
										hero={
											article.fields.heroImage
												? {
														src: article.fields.heroImage?.fields.file?.url,
														alt:
															article.fields.heroImage?.fields.description ??
															article.fields.heroImage?.fields.title ??
															'',
														preload: false,
														loading: 'lazy',
													}
												: undefined
										}
									>
										<RichText document={article.fields.summary} />
									</ArticleTile>
								)}
							</For>
						</div>

						<Pagination
							currentPage={1}
							lastPage={Math.ceil(articles()!.total / perPage)}
							url={(page) => `/insider/page/${page}`}
							nextTitle="Older"
							prevTitle="Newer"
						/>
					</Section>
				</Show>
			</Page>
		</Container>
	);
}

const getArticles = createContentfulListRequest('articles', {
	'fields.articleType': 'The Troon Insider',
	limit: perPage,
});
